import React, {useState, useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import SpinnerGuardar from './SpinnerGuardar';
import { useNavigate  } from "react-router-dom";

const ModalFolioCancelado = ({nota,fecha, neto}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true);RehacerFolios();}
    var navigate = useNavigate();

    const [guardado, setGuardado] = useState(false);
    const [mensaje, setMensaje] = useState('Rehacer Folios');
    const [detalle, setDetalle] = useState([]);

    const RehacerFolios = () => {

      localStorage.removeItem('empresa');
      localStorage.removeItem('seguimiento');

                setTimeout(()=>{
                  handleClose();
                  // navigate('/crearDev');
                  // window.location.href = "m";
                  window.location.reload();
              },4000);

    }
  


  return (
    
    <div className="col-12 col-sm-3 col-md-3 py-2">


    <button type="button" className="btn btn-danger" onClick={()=>(handleShow())}>Rehacer Folio(s)</button>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{mensaje}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                
        <div style={{margin:'auto',textAlign: 'center'}} >
          <span>Folio(s) Cancelados correctamente</span>
          <p>Sera redireccionado para crear un nuevo Folio.</p>
        </div>               

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  )
}

export default ModalFolioCancelado