import React from 'react'
import Table from 'react-bootstrap/Table';
import { formatearProd } from '../../assets/Utils/Utils.js';

export const DetalleCabeceraFolio = ({detalleFolio, cargandoDetalle}) => {
  return (
    <div>
        <Table striped bordered hover>
        <thead>
            <tr>
            <th>#</th>
            <th>Codigo</th>
            <th>Descripcion</th>
            <th>Cantidad</th>
            </tr>
        </thead>
        <tbody>
            {
                !cargandoDetalle ? (
                detalleFolio.map((item,index)=>(
                    

                    <tr>
                    <td>{index + 1}</td>
                    <td>{formatearProd(item.prod_id)}</td>
                    <td>{ item.prod_descripcion_venta ? item.prod_descripcion_venta : `${item.nombre_at != null ? item.nombre_at: ''} ${item.nombre_aute != null ? item.nombre_aute: ''} ${item.nombre_g != null ? item.nombre_g: ''} ${item.nombre_hd != null ? item.nombre_hd: ''}` }</td>
                    <td>{item.cantidad_devolver ? item.cantidad_devolver : item.cantidad}</td>
                    </tr>
                ))) : (
                    <tr>
                    <td colSpan={4}>Cargando Detalle ..!</td>
                    </tr>)
            }

        </tbody>
        </Table>
    </div>
  )
}
