import React from 'react'
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {DetalleCabeceraFolio}  from '../DetalleCabeceraFolio/DetalleCabeceraFolio';


const CabeceraFolio = ({folios, idFolio, productosSeleccionados}) => {

  const [detalleFolio, setdetalleFolio] = useState([]);
  const [cargandoDetalle, setCargandoDetalle] = useState(false);


  const selectAccordion = async(e) => {

    setCargandoDetalle(true);

    console.log(e)

    if(!e)return;

    if(idFolio==e){ // folio actual, uso productos seleccionados de las props

      setdetalleFolio(productosSeleccionados);
      setCargandoDetalle(false);

    }else{ // si no es igual , llamo por el id_folio

          // llamada a cabecera de folio por el id_folio
          const response = await fetch(`${process.env.REACT_APP_URLBACKEND}detalleFolio/${e}`);
          const detalle = await response.json();
          // console.log(detalle);
          setdetalleFolio(detalle);
          setCargandoDetalle(false);
    }
    
  }

    

  return (
    <Accordion  onSelect={e => selectAccordion(e)}> {/** defaultActiveKey={item.id_folio} */}
    {
      folios.length > 0 ? (
      folios.map((item,index) => (
        
          <Accordion.Item eventKey={item.id_folio}>
            <Accordion.Header><span className='me-3'><b>Folio</b> : {item.id_folio}</span> <span><b>Razon Social</b> : {item.razon_social}</span>    <span className='ms-2'><b>Motivo</b> : {item.motivo_dev}</span></Accordion.Header>
            <Accordion.Body>
              <DetalleCabeceraFolio detalleFolio={detalleFolio} cargandoDetalle={cargandoDetalle}/>
            </Accordion.Body>
          </Accordion.Item>
        
      ))) : (<div><span>Cargando informacion ...</span></div>)
    }

    </Accordion>
  )
}

export default CabeceraFolio