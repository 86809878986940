export const formatearProd = (prod_id) =>{

    if(prod_id.includes('-'))return prod_id;
           
    let cuerpo = '';
    let guion = '';
    cuerpo = prod_id.substring(0,5);
    guion = prod_id.substring(5);
    let prod_id2 = cuerpo+'-'+guion;
    // console.log(cuerpo+'-'+guion);

    return prod_id2;

}